import React from "react";
import Layout from "../components/General/Layout.component";
import { referencesPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";
import BC from "../components/General/BC.component";
import ReferenceTop from "../components/References/ReferenceTop.component";
import ReferenceBottom from "../components/References/ReferenceBottom.component";
import { useState } from "react";

const Referenciaink = () => {
  const [openCallBack, setOpenCallBack] = useState(false);
  return (
    <Layout openCallBack={openCallBack} setOpenCallBack={setOpenCallBack}>
      {genPageInfo(referencesPage)}
      <BC
        title="Referenciáink"
        sub={[{ text: "Kezdőlap", href: "/" }, { text: "Mi csináltuk" }]}
      />
      <ReferenceTop />
      <ReferenceBottom setOpenCallBack={setOpenCallBack} />
    </Layout>
  );
};

export default Referenciaink;
