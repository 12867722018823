import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const RefItem = styled.div`
  display: flex;
  margin-bottom: 10rem;
  .left {
    flex: 2;
    position: relative;
    z-index: 1;
    .title {
      font-size: 2rem;
      font-weight: 900;
    }
    .sub {
      color: ${(props) => props.theme.darkGray};
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    .slider {
      width: calc(calc(1170px / 12) * 8);
      @media (max-width: 992px) {
        width: 100%;
      }
      button.slick-prev {
        bottom: 10px;
        left: 0;
        top: auto;
        z-index: 2;
        &:before {
          opacity: 0.9999;
          content: "<";
          background: white;
          color: ${(props) => props.theme.darkGray};
          padding: 1.2rem 2rem 2rem 2rem;
          font-weight: 900;
        }
      }
      button.slick-next {
        bottom: 10px;
        left: 5rem;
        top: auto;
        z-index: 2;
        &:before {
          opacity: 0.9999;
          content: ">";
          background: ${(props) => props.theme.orange};
          color: white;
          padding: 1.2rem 2rem 2rem 2rem;
          font-weight: 900;
        }
      }
    }
  }
  .right {
    flex: 1;
    background: white;
    position: relative;
    z-index: 0;
    padding: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &:before {
      content: "";
      position: absolute;
      height: calc(100% + 3rem);
      width: 6rem;
      left: -6rem;
      top: 0;
      display: block;
      background: white;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      height: 3rem;
      width: 100%;
      bottom: -3rem;
      left: 0;
      display: block;
      background: white;
      z-index: -1;
    }
    .title {
      font-weight: 900;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
    .content {
      color: ${(props) => props.theme.darkGray};
      line-height: 2;
      margin-bottom: 2rem;
      font-size: 1.4rem;
    }
    .list {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.1rem;
      .list-item {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 2rem;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 4rem;
    .left,
    .right {
      flex: 1;
    }
    .gatsby-image-wrapper {
      width: 100%;
    }
    .right {
      &::before,
      &:after {
        display: none;
      }
    }
  }
`;

const RefContainer = styled.section`
  padding: 8rem 0;
  background: ${(props) => props.theme.lighterGray};
  @media (max-width: 992px) {
    padding: 4rem 0;
  }
`;

const ReferenceTop = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease",
    initialSlide: 0,
  };
  return (
    <RefContainer>
      <div className="container">
        <RefItem>
          <div className="left">
            <div className="title">
              Társasházi lakás - Tolópajzsos bejárati ajtó
            </div>
            <div className="sub">Minőségi nyílászárók – Penthouse lakás</div>
            <div className="slider">
              <Slider {...sliderSettings}>
                <div>
                  <StaticImage
                    src="../../images/reference1/ref1-1.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../images/reference1/ref1-2.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../images/reference1/ref1-3.jpg"
                    alt=""
                  />
                </div>
              </Slider>
            </div>
          </div>
          <div className="right">
            <div className="title">A projekt bemutatása</div>
            <div className="content">
              A megrendelőnk egyedi elképzelésekkel keresett fel bennünket. A
              kérése az volt, hogy a bejárati ajtó tolópajzsos mechanikájú és
              ajtó színe egyedi keverésű, antracit színű legyen. A gyártási
              folyamat három hét alatt sikeresen megtörtént, majd a megrendelők
              igényeihez alkalmazkodva elvégeztük a beépítést.
            </div>
            <div className="list">
              <div className="list-item">
                <p>Átadás </p>
                <span>2018</span>

                <p>Helyszín</p>
                <span>Debrecen</span>

                <p>Munka</p>
                <span>Gyártás és kivitelezés</span>
              </div>
            </div>
          </div>
        </RefItem>
        <RefItem>
          <div className="left">
            <div className="title">Hatvan Corner</div>
            <div className="sub">Minőségi nyílászárók – Penthouse lakás</div>
            <div className="slider">
              <div className="slider-images">
                <StaticImage src="../../images/reference2/ref2-1.jpg" alt="" />
              </div>
              <div className="slider-buttons">
                <span className="prev"></span>
                <span className="next"></span>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="title">A projekt bemutatása</div>
            <div className="content">
              A gyártási folyamat három hét alatt sikeresen megtörtént, majd a
              megrendelők igényeihez alkalmazkodva elvégeztük a beépítést.
              Igényes lakók számára gyártottunk és építettünk Penthouse
              lakásokba kiváló hőszigetelő tulajdonságokkal rendelkező, modern
              kialakítású, esztétikus, a magas minőségi követelményeknek
              megfelelő nyílászárókat
            </div>
            <div className="list">
              <div className="list-item">
                <p>Átadás </p>
                <span>2018</span>

                <p>Helyszín</p>
                <span>Debrecen</span>

                <p>Munka</p>
                <span>Gyártás és kivitelezés</span>
              </div>
            </div>
          </div>
        </RefItem>
        <RefItem>
          <div className="left">
            <div className="title">Hatvan Corner</div>
            <div className="sub">Minőségi nyílászárók – Penthouse lakás</div>
            <div className="slider">
              <div className="slider-images">
                <StaticImage src="../../images/reference3/ref3-1.jpg" alt="" />
              </div>
              <div className="slider-buttons">
                <span className="prev"></span>
                <span className="next"></span>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="title">A projekt bemutatása</div>
            <div className="content">
              A gyártási folyamat három hét alatt sikeresen megtörtént, majd a
              megrendelők igényeihez alkalmazkodva elvégeztük a beépítést.
              Igényes lakók számára gyártottunk és építettünk Penthouse
              lakásokba kiváló hőszigetelő tulajdonságokkal rendelkező, modern
              kialakítású, esztétikus, a magas minőségi követelményeknek
              megfelelő nyílászárókat
            </div>
            <div className="list">
              <div className="list-item">
                <p>Átadás </p>
                <span>2018</span>

                <p>Helyszín</p>
                <span>Debrecen</span>

                <p>Munka</p>
                <span>Gyártás és kivitelezés</span>
              </div>
            </div>
          </div>
        </RefItem>
      </div>
    </RefContainer>
  );
};

export default ReferenceTop;
