import WindowIcon from "../../images/ablak-ikon.svg";
import DoorIcon from "../../images/ajto-ikon.svg";
import CallbackIcon from "../../images/visszahivas-ikon.svg";
import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

const RefBottom = styled.div`
  background: ${(props) => props.theme.lighterGray};
  padding-bottom: 3rem;
  .title {
    font-family: "Rubik", sans-serif;
    font-weight: 900;
    font-size: 4rem;
    text-transform: uppercase;
    margin-bottom: 4rem;
    @media (max-width: 992px) {
      font-size: 3rem;
    }
  }
  .list-cta {
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media (max-width: 1170px) {
      grid-template-columns: 1fr;
    }
    .cta {
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 6rem 4rem;
      svg {
        width: auto;
        height: 70px;
      }
      p {
        font-weight: 900;
        font-size: 2.7rem;
        margin-left: 2rem;
      }
      @media (max-width: 992px) {
        svg {
          height: 4rem;
        }
        p {
          font-size: 2rem;
        }
      }
    }
  }
`;

const ReferenceBottom = (props) => {
  return (
    <RefBottom>
      <div className="container">
        <div className="title">Nyílászáróra van szüksége?</div>
        <div className="list-cta">
          <div className="cta" onClick={() => navigate("/ablak-tervezo")}>
            <WindowIcon />
            <p>
              Ablak
              <br />
              tervező
            </p>
          </div>
          <div className="cta">
            <DoorIcon />
            <p>
              Ajtó
              <br />
              tervező
            </p>
          </div>
          {/* <div className="cta" onClick={() => props.setOpenCallBack(true)}>
            <CallbackIcon />
            <p>
              Visszahívást
              <br />
              kérek
            </p>
          </div> */}
        </div>
      </div>
    </RefBottom>
  );
};

export default ReferenceBottom;
